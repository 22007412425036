import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { MarketingService } from '~/shared/api/services/marketing'
import { Loaders } from './types'
import { SelectOption } from '~/shared/ui'
import { mapDataForSelect } from '~/shared/lib'

export class MarketingStore {
  loaders: Loaders = {
    products: false,
    loadMoreSales: false,
    loadMoreCoupons: false,
    categoriesForModal: false,
    categoriesForDiscount: false,
    categoriesForCoupon: false,
    createSlider: false,
    sales: false,
    coupons: false,
    sliders: false,
    stores: false,
    createCoupon: false,
    createSale: false,
    deleteSale: false,
    deleteSlider: false,
    createSlide: false,
    deleteCoupon: false,
    updateSlider: false,
  }

  categoriesForDiscount: SelectOption[] = []

  categoriesForCoupon: SelectOption[] = []

  categoriesForModal: SelectOption[] = []

  subContentOpens: string[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setIdsSubContentOpens = (id: string) => {
    this.subContentOpens = this.subContentOpens.includes(id)
      ? this.subContentOpens.filter((e) => e !== id)
      : [...this.subContentOpens, id]
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  getCategories = async (
    mobxStoreName:
      | 'categoriesForModal'
      | 'categoriesForCoupon'
      | 'categoriesForDiscount',
    findStoreId?: string,
  ) => {
    this.activeLoader(mobxStoreName)

    try {
      const { data } = await MarketingService.getCategories({
        params: { stores: [findStoreId] },
      })

      runInAction(() => {
        this[mobxStoreName] = mapDataForSelect(data, 'id', 'name')
        this.closeLoader(mobxStoreName)
      })
    } catch (error: unknown) {
      if (error?.name !== 'CanceledError') {
        runInAction(() => {
          this.closeLoader(mobxStoreName)
        })
      } else if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    }
  }

  clearCategoriesForModal = () => {
    this.categoriesForModal = []
  }

  clearCategoriesForCoupon = () => {
    this.categoriesForCoupon = []
  }
}

export default MarketingStore

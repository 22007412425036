import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MultiValue } from 'react-select'
import { FilterName, ProductsFilters } from '~/entities/products'
import { useStore } from '~/app/store'
import { debounce, useFilters } from '~/shared/lib'
import { SelectOption } from '~/shared/ui'

export const ProductsFeatures = observer(() => {
  const { productsStore, globalStore } = useStore()
  const { statuses, getMappedSelectFilters, getFilterByName } = productsStore

  const [params] = useSearchParams()

  const [productName, setProductName] = useState(params.get('q') || '')
  const [vendorCode, setVendorCode] = useState(params.get('vendorCode') || '')
  const [sku, setSku] = useState(params.get('sku') || '')

  const {
    handleFilter,
    clearAllFilters,
    isEmptyFilters,
    getActiveFiltersData,
  } = useFilters({
    savedFiltersKey: 'productsFilters',
    selectData: {
      manufacturers: getMappedSelectFilters.manufacturers,
      options: getMappedSelectFilters.options,
      stores: getMappedSelectFilters.stores,
      statuses,
      q: [{ value: productName, label: '' }],
      sku: [{ value: sku, label: '' }],
      vendorCode: [{ value: vendorCode, label: '' }],
    },
  })

  const clear = useCallback(() => {
    clearAllFilters()
    setProductName('')
    setVendorCode('')
    setSku('')
  }, [clearAllFilters])

  const handleSetProductInputFilters = useCallback(
    (e: string, key: string) => {
      handleFilter(key, [e])
    },
    [handleFilter],
  )

  const debouncedSendRequest = useMemo(() => {
    return debounce(
      (value, key) =>
        handleSetProductInputFilters(value as string, key as string),
      500,
    )
  }, [handleSetProductInputFilters])

  const onChangeProductInputFilters = (e: string, key: string) => {
    if (key === 'q') {
      setProductName(e)
    }

    if (key === 'sku') {
      setSku(e)
    }

    if (key === 'vendorCode') {
      setVendorCode(e)
    }

    debouncedSendRequest(e, key)
  }

  const debouncedSendGetCategoryByName = useMemo(() => {
    return debounce((name, value) => {
      getFilterByName(name as FilterName, value as string)
    }, 500)
  }, [getFilterByName])

  const handleChangeSelectInput = useCallback(
    (name: string, value: string) => {
      debouncedSendGetCategoryByName(name, value)
    },
    [debouncedSendGetCategoryByName],
  )

  const handleSelectChange = useCallback(
    (key: string, value: MultiValue<SelectOption>) => {
      handleFilter(key, value)
    },
    [handleFilter],
  )

  const debouncedFindCategories = useMemo(() => {
    return debounce((action) => {
      globalStore.getCategories(action as 'loadMore' | 'reset', 'productPage')
    }, 500)
  }, [globalStore])

  const setFilterQ = (value: string) => {
    globalStore.setFiltersQ('productPage', value)
    debouncedFindCategories('reset')
  }

  return (
    <div>
      <ProductsFilters
        getSelectActiveData={getActiveFiltersData}
        changeFilters={handleSelectChange}
        manufacturerData={getMappedSelectFilters.manufacturers}
        categoriesData={
          globalStore.filtersBySelect.categories
            ? globalStore.filtersBySelect.categories.map(
                (el) =>
                  ({
                    label: el.name,
                    value: el.id,
                  } as SelectOption),
              )
            : []
        }
        optionsData={getMappedSelectFilters.options}
        onChangeProductInputFilters={onChangeProductInputFilters}
        searchName={productName}
        searchSku={sku}
        vendorCode={vendorCode}
        handleChangeSelectInput={handleChangeSelectInput}
        statuses={statuses}
        clearAllFilters={clear}
        isEmptyFilters={isEmptyFilters}
        setFiltersQ={setFilterQ}
        findCategories={debouncedFindCategories}
        categoryLoading={globalStore.loaders.categories}
      />
    </div>
  )
})

import { cva } from 'class-variance-authority'

export const tableHead = cva('w-full h-[35px] ', {
  variants: {
    variant: {
      default: 'bg-[#EBEBEB] text-gray-500 text-sm  border-t-0 text-left px-10',
      secondary: 'bg-[#EBEBEB] text-gray-500 text-center text-sm  border-t-0 ',
      white: 'bg-white text-gray-500 text-left text-sm  border-t-0',
    },
    border: {
      top: 'border-t',
      bottom: 'border-b',
    },
  },
  defaultVariants: {
    variant: 'default',
    border: 'top',
  },
})

import { ColumnDef } from '@tanstack/react-table'
import { Item } from '~/shared/api'
import { ArrowToDownProduct } from '../ui/sub-content-product-2'

export const ITEMS_TABLE_CONFIG: ColumnDef<Item>[] = [
  {
    header: 'name',
    accessorKey: 'name',

    cell: (row) => {
      const { original } = row.cell.row

      return <ArrowToDownProduct originalData={original} />
    },
    maxSize: 150,
  },
  {
    header: 'inStock',
    accessorKey: 'inStock',
    cell: (row) => {
      const { original } = row.cell.row

      return <div>{original.count}</div>
    },
    maxSize: 100,
  },
  {
    header: 'InTheOrder',
    accessorKey: 'InTheOrder',
    cell: (row) => {
      const { original } = row.cell.row
      return <div>{original.countInOrders}</div>
    },
    maxSize: 100,
  },
  {
    header: 'IsRequired',
    accessorKey: 'IsRequired',
    cell: (row) => {
      const { original } = row.cell.row
      return <div>{original.countInDemands}</div>
    },
    maxSize: 100,
  },
  {
    header: 'SentInTheLastMonth',
    accessorKey: 'SentInTheLastMonth',
    cell: (row) => {
      const { original } = row.cell.row
      return <div>{original.soldLastMonthCount}</div>
    },
    maxSize: 100,
  },
]

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { SelectOption } from '~/shared/ui'
import { Loaders, Warehouses, Filters, OrderOptions } from './types'
import { RetailService } from '~/shared/api/services/retail'
import { handleAxiosError, parseFormData } from '~/shared/lib'
import { Option } from '~/shared/api'
import { ProductVariation, Total } from '~/shared/api/services/retail/types'

export class RetailStore {
  loaders: Loaders = {
    warehouses: false,
    loadedAllFilters: false,
    createOrder: false,
  }

  filters: Filters = {
    storeId: '',
    warehouseId: '',
    product: '',
    manufacturer: null,
    option: null,
    optionValueId: null,
  }

  orderList: ProductVariation[] = []

  productList: ProductVariation[] = []

  orderCount = 0

  orderOptions: OrderOptions = {
    sale: '',
    saleType: 'persent',
    retailPaymentType: null,
    comment: '',
  }

  warehouses: Warehouses[] = []

  isEndProductList = false

  isListLoading = true

  IsProductInOrder = false

  isMoreLoading = false

  offset = 0

  limit = 20

  options: Option[] = []

  constructor() {
    makeAutoObservable(this)
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  resetOrderOptions = () => {
    this.orderOptions = {
      sale: '',
      saleType: 'persent',
      retailPaymentType: null,
      comment: '',
    }
  }

  resetFilters = (clearAll = false) => {
    if (clearAll) {
      this.filters = {
        storeId: '',
        warehouseId: '',
        product: '',
        manufacturer: null,
        option: null,
        optionValueId: null,
      }
    } else {
      this.filters = {
        ...this.filters,
        product: '',
        manufacturer: null,
        option: null,
        optionValueId: null,
      }
    }
    this.orderList = []
  }

  setIsProductInOrder = () => {
    if (this.orderList.length >= 1) {
      this.IsProductInOrder = true
    } else {
      this.IsProductInOrder = false
    }
  }

  getFilters = async () => {
    return Promise.all([this.getWarehouses()])
      .then((res) => {
        runInAction(() => {
          const [warehouses] = res
          if (warehouses) {
            this.warehouses = warehouses
          }
        })
      })
      .finally(() => {
        runInAction(() => {
          this.loaders.loadedAllFilters = true
        })
      })
  }

  getWarehouses = async () => {
    try {
      const { data } = await RetailService.getWarehouses()
      runInAction(() => {
        this.warehouses = data
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getProductVariation = async (action: 'filters' | 'loadMore' | 'init') => {
    if (this.isEndProductList && action === 'loadMore') return

    if (['filters', 'init'].includes(action)) {
      this.offset = 0
      this.limit = 20
    }

    runInAction(() => {
      this.isListLoading = ['filters', 'init'].includes(action)
      this.isMoreLoading = action === 'loadMore'
    })

    try {
      const filterParams = parseFormData(this.filters)

      const params: Record<string, any> = {
        limit: this.limit,
        offset: this.offset,
        ...filterParams,
      }
      const { data } = await RetailService.getProductVariation({ params })

      const transformedData = data.map((item: any) => ({
        ...item,
        price: item.price / 100,
      }))
      runInAction(() => {
        this.offset += this.limit
        this.isEndProductList = data.length < this.limit
        if (action === 'loadMore') {
          this.productList = [...this.productList, ...transformedData]
        } else {
          this.productList = transformedData
        }
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isListLoading = false
        this.isMoreLoading = false
      })
    }
  }

  getOptions = async (q?: string) => {
    try {
      const { data } = await RetailService.getOptions({ params: { q } })
      runInAction(() => {
        this.options = data
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  setFilters = (name: keyof Filters, value: string | null | SelectOption) => {
    this.filters = { ...this.filters, [name]: value }
  }

  setOrderOptions = (
    name: keyof OrderOptions,
    value: string | SelectOption,
  ) => {
    this.orderOptions = { ...this.orderOptions, [name]: value }
  }

  setOrderList = (product: ProductVariation, inputValue?: string) => {
    const check = this.orderList.some(
      (orderProduct) =>
        orderProduct.productId === product.productId &&
        orderProduct.variationId === product.variationId,
    )
    if (check) {
      this.orderList = this.orderList.map((prd) =>
        prd.productId === product.productId &&
        prd.variationId === product.variationId
          ? {
              ...prd,
              count:
                typeof inputValue !== 'undefined' ? inputValue : +prd.count + 1,
            }
          : prd,
      )
    } else {
      this.orderList = [...this.orderList, { ...product, count: 1 }]
    }
  }

  removeProduct = (id: string, varId: string) => {
    this.orderList = this.orderList.filter(
      (e) => !(e.productId === id && e.variationId === varId),
    )
  }

  createOrder = async (totals: Total[]) => {
    this.activeLoader('createOrder')

    try {
      const payload = {
        order: {
          storeId: this.filters.storeId,
          warehouseId: this.filters.warehouseId,
          products: this.orderList.map((prd) => ({
            type: 'default',
            variationId: prd.variationId,
            quantity: Number(prd.count),
            priceItemValue: prd.price,
            priceTotalValue: Number(prd.count) * prd.price,
            priceItem: `${prd.price} ₽`,
            priceTotal: `${Number(prd.count) * prd.price} ₽`,
            productDetails: prd,
          })),
          retailPaymentType:
            this.orderOptions?.retailPaymentType?.value ?? null,
          paymentMethod: null,
          comment: this.orderOptions.comment,
          customer: {
            email: null,
            firstname: null,
            fullName: null,
            lastname: null,
            phone: null,
          },
          recipient: {
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
            fullName: null,
            address: null,
            city: null,
            country: null,
            countryZone: null,
            postalCode: null,
          },
          address: {
            address: null,
            city: null,
            country: null,
            countryZone: null,
            postalCode: null,
          },
          totals,
          shippingMethod: 'pickup',
          digitalProducts: [],
          label: 'RM',
          parentId: null,
        },
      }
      const { data } = await RetailService.createOrder(payload)
      return data
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('createOrder')
      })
    }
  }
}

export default RetailStore

import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import {
  AvailableProductModel,
  AvailableSingleProductModel,
  Brand,
  CreateFeatureModel,
  CreateMaterialModel,
  EditableProductModel,
  Feature,
  Items,
  Manufacturer,
  Material,
  Option,
  Product,
  ShippingModel,
  SingleOptionModel,
} from './types'

export class ProductsService {
  static getProducts(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Product[]>> {
    return apiService.get<AxiosResponse<Product[]>>(`/products`, config)
  }

  static getItems(id: string): Promise<AxiosResponse<Items>> {
    return apiService.get<AxiosResponse<Items>>(`/products/${id}/items`)
  }

  static getRequiredLocalization(): Promise<{ localizations: string[] }> {
    return apiService.get<{ localizations: string[] }>(
      '/required-localizations',
    )
  }

  static getProductById(
    id: string,
  ): Promise<AxiosResponse<EditableProductModel>> {
    return apiService.get<AxiosResponse<EditableProductModel>>(
      `/products/${id}/edit`,
    )
  }

  static createProduct(data?: unknown): Promise<AxiosResponse<{ id: string }>> {
    return apiService.post<AxiosResponse<{ id: string }>>(`/products`, data)
  }

  static getAvailableProducts(
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse<AvailableProductModel[]>> {
    return apiService.get<AxiosResponse<AvailableProductModel[]>>(
      `/products/availabilities`,
      config,
    )
  }

  static getAvailableProduct(
    id: string,
  ): Promise<AxiosResponse<AvailableSingleProductModel>> {
    return apiService.get<AxiosResponse<AvailableSingleProductModel>>(
      `/products/available/${id}`,
    )
  }

  static updateProduct(
    id: string,
    data?: unknown,
  ): Promise<AxiosResponse<{ id: string }>> {
    return apiService.put<AxiosResponse<{ id: string }>>(
      `/products/available/${id}`,
      data,
    )
  }

  static getManufacturers(): Promise<AxiosResponse<Manufacturer[]>> {
    return apiService.get<AxiosResponse<Manufacturer[]>>('/manufacturer')
  }

  static getOptions(
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse<Option[]>> {
    return apiService.get<AxiosResponse<Option[]>>('/options', config)
  }

  static getOptionById(id: string): Promise<AxiosResponse<SingleOptionModel>> {
    return apiService.get<AxiosResponse<SingleOptionModel>>(`/options/${id}`)
  }

  static getMaterials(): Promise<AxiosResponse<Material[]>> {
    return apiService.get<AxiosResponse<Material[]>>('/materials')
  }

  static addMaterial(
    data: CreateMaterialModel,
  ): Promise<AxiosResponse<Material>> {
    return apiService.post<AxiosResponse<Material>>('/materials', data)
  }

  static getFeatures(): Promise<AxiosResponse<Feature[]>> {
    return apiService.get<AxiosResponse<Feature[]>>('/features')
  }

  static addFeature(data: CreateFeatureModel): Promise<AxiosResponse<Feature>> {
    return apiService.post<AxiosResponse<Feature>>('/features', data)
  }

  static generateBarcode(
    id: string,
    payload: Record<string, string>,
  ): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(
      `/products/${id}/generateBarcode`,
      payload,
    )
  }

  static getBrands(): Promise<AxiosResponse<Brand[]>> {
    return apiService.get<AxiosResponse<Brand[]>>('/brands')
  }

  static getShipping(): Promise<AxiosResponse<ShippingModel[]>> {
    return apiService.get<AxiosResponse<ShippingModel[]>>('/shipping')
  }

  static hideProductOption(data: {
    productId: string
    optionId: string
    optionValueId: string
  }): Promise<AxiosResponse<unknown>> {
    return apiService.post<AxiosResponse<unknown>>(
      `/products/${data.productId}/options/${data.optionId}/hide`,
      {
        default_option_value_id: data.optionValueId,
      },
    )
  }

  static returnOption(data: {
    productId: string
    optionId: string
  }): Promise<AxiosResponse<SingleOptionModel>> {
    return apiService.delete<AxiosResponse<SingleOptionModel>>(
      `/products/${data.productId}/options/${data.optionId}/return`,
    )
  }

  static getFilterByName<T>(
    name: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T[]>> {
    return apiService.get<AxiosResponse<T[]>>(`/${name}`, config)
  }
}

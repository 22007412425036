import { cva } from 'class-variance-authority'

export const tableHead = cva(['rounded-b-none'], {
  variants: {
    rounded: {
      none: 'rounded-b-none',
      default: 'rounded-2xl',
    },
  },
  defaultVariants: {
    rounded: 'default',
  },
})

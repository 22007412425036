import { cva } from 'class-variance-authority'

export const table = cva(['bg-white shadow', 'table-fixed', 'w-full'], {
  variants: {
    rounded: {
      none: '',
      small: 'rounded-sm',
      large: 'rounded-2xl',
    },
    bordered: {
      true: 'border border-gray-300',
      false: '',
    },
  },
  defaultVariants: {
    rounded: 'large',
    bordered: false,
  },
})

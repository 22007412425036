/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next'
import React from 'react'
import { flexRender } from '@tanstack/react-table'
import { TableHeadProps } from './types'
import { tableHead } from './config'

export function TableHead<T>({
  className,
  columns = [],
  aroundwithout = true,
  variant = 'default',
  border,
}: TableHeadProps<T>) {
  const { t } = useTranslation()

  return (
    <thead className={`${tableHead({ variant, border })} ${className}`}>
      {columns.map((headerGroup) => {
        return (
          <React.Fragment key={headerGroup.id}>
            <tr>
              {headerGroup.headers.map((header, index) => {
                const isFirstHeader = index === 0
                const isLastHeader = index === headerGroup.headers.length - 1
                const headerStyles = {
                  width: header.getSize(),
                  borderTopLeftRadius:
                    aroundwithout && isFirstHeader ? '10px' : '0',
                  borderTopRightRadius:
                    aroundwithout && isLastHeader ? '10px' : '0',
                  fontWeight: 'normal',
                  padding: isFirstHeader ? '0px 20px' : '0px 5px',
                }

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    {...(aroundwithout ? { aroundwithout: 'true' } : {})}
                    style={{
                      ...headerStyles,
                    }}
                    scope="col"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          t(
                            header.column.columnDef
                              .header as keyof (typeof lan)['translation'],
                          ),
                          header.getContext(),
                        )}
                  </th>
                )
              })}
            </tr>
            <tr>
              <td
                colSpan={headerGroup.headers.reduce(
                  (acc, header) => acc + header.colSpan,
                  0,
                )}
              />
            </tr>
          </React.Fragment>
        )
      })}
    </thead>
  )
}

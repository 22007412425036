import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import { PayloadCreateOrder, ProductVariation, Warehouses } from './types'
import { Option } from '../products/types'

export class RetailService {
  static getWarehouses(): Promise<AxiosResponse<Warehouses[]>> {
    return apiService.get<AxiosResponse<Warehouses[]>>(`/warehouses`)
  }

  static getProductVariation(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse<ProductVariation[]>>(
      `products/variation`,
      config,
    )
  }

  static getOptions(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Option[]>> {
    return apiService.get<AxiosResponse<Option[]>>('/options', config)
  }

  static createOrder(order: PayloadCreateOrder): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`order/create`, order)
  }
}
